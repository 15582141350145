/* You can add global styles to this file, and also import other style files */
@import "theme";
@import "@fortawesome/fontawesome-free/css/all.css";
@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Muli', sans-serif;
  background: #f2f2f2;
}

.mat-snack-bar-container {
  &.success {
    background: #28a745;
    color: white;
  }

  &.default {
    background: #343a40;
    color: white;
  }

  &.info {
    background: #17a2b8;
    color: white;
  }

  &.error {
    background: #dc3545;
    color: white;
  }
}

.mat-menu-panel {
  border-radius: 0 !important;
}

.mat-raised-button {
  border-radius: 0 !important;
}

.mat-mdc-raised-button {
  &.mat-green {
    background-color: green !important;
    color: #fff !important;
  }

  &.mat-blue {
    background-color: #17a2b8 !important;
    color: #fff !important;
  }

  &.mat-brown-orange {
    background-color: #db3700 !important;
    color: #fff !important;
  }

  &.mat-gray {
    background-color: #989797 !important;
    color: #fff !important;
  }
}


.jwplayer {
  .jw-text-duration, .jw-text-countdown, .jw-text-elapsed, .jw-slider-time {
    display: none !important;
  }
}

.heading {
  padding: 20px 0;
}

.mat-mdc-card.gray {
  background: #e3e3e3;
}

.mat-dialog-title {
  margin: 0 !important;
}

.mat-dialog-actions {
  margin-bottom: -5px !important;
}

.mat-dialog-content {
  h3, h4, h5 {
    margin: 0 0 5px 0;
  }
}

.img-responsive {
  width: 100%;
  height: auto;
}

.no-margin {
  margin: 0;
}

.mat-error {
  color: #db3700;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #db3700;
}
